<script>
import Client from "./AxiosClient";

const get = (url) => {
  return Client.get(`${url}`);
};

const post = (url, obj) => {
  return Client.post(`${url}`, obj);
};

const postDownload = (url, obj) => {
  return Client.post(`${url}`, obj, { responseType: "arraybuffer" });
};

const update = (url, obj) => {
  return Client.put(`${url}`, obj);
};

const deleted = (url, obj) => {
  return Client.delete(`${url}`, { data: obj });
};

export default {
  get,
  post,
  update,
  deleted,
  postDownload,
};
</script>

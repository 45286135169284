import Vue from "vue";
import lodash from "lodash";
import store from "@/store";

export default Vue.mixin({
  methods: {
    checkAccessRole(module, actions) {
      var flag = false;
      lodash.map(store.state.actions, act => {
        var str = lodash.split(act, ":");
        var modAct = lodash.split(str[str.length - 1], "/");
        var actn = modAct[1];
        var mod = modAct[0];
        if (mod == module) {
          if (lodash.startsWith(actn, "*")) {
            flag = true;
            return false;
          } else {
            lodash.map(actions, action => {
              if (
                lodash.startsWith(actn, action + "*") ||
                lodash.startsWith(actn, action)
              ) {
                flag = true;
                return false;
              }
            });
          }
        }
      });
      //console.log("modules",module,flag);
      return flag;
    },
    checkURL() {
      var _self = this;
      var flag = _self.checkAccessRole(
        _self.$route.meta.module,
        _self.$route.meta.action
      );
      // console.log("_self.$route.meta.module",_self.$route.meta.module);
      // console.log("_self.$route.meta.action",_self.$route.meta.action);
      // console.log("flag",flag);
      if (!flag) {
        _self.$router.push("/dashboard");
        return;
      }
    },
    formatCurrency(money) {
      if (money == null || money == undefined) {
        return "";
      }

      return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatCurrencyComma(money) {
      if (money == null || money == undefined) {
        return "";
      }

      return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDayString(datetime) {
      let hour = String(datetime.getHours()).padStart(2, '0');
      let minute = String(datetime.getMinutes()).padStart(2, '0');
      let dd = String(datetime.getDate()).padStart(2, '0');
      let mm = String(datetime.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = datetime.getFullYear();

      return `${hour}h${minute} ngày ${dd} tháng ${mm} năm ${yyyy}`;
    },
    exportExcel(data) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.setAttribute('download', 'danh_sach_kiem_ke.xlsx');
      document.body.appendChild(link);
      link.click();
    }
  }
});

<script>
// const baseContractDomain = process.env.VUE_APP_BaseContractDomain;
// const baseContractURL = `${baseContractDomain}`;

// const baseKPIDomain = process.env.VUE_APP_BaseKPIDomain;
// const baseKPIURL = `${baseKPIDomain}`;

const SQSQUEUE_PartnerName = "F88_LOS";
//api/organizations/list-pgd
const API = Object.freeze({
  Get_GetListUser: "/users",
  Get_UserProfile: "api/v3/Users/GetProfile?module=copa",
  Get_ListTransaction: "organizations/list-pgd",
  Post_GetListRole: "Policy/GetMenuByRoles",
  Get_GetActions: "actions",
  Get_GetSystems: "master-data?TBL=APP_PORTAL&ParentCode=FE",
  Get_PresignedUrl: "File/PresignedUrl",
  Get_SignUrl: "File/SignUrl",
  //----------------------------FundTransfer----------------
  FundTransfer: {
    List: "FundTransfers/ListFundTransfer",
    Create: "FundTransfers/CreateFundTransfer",
    ExportExcel: "FundTransfers/CreateExportFundTransfer",
    Update:"FundTransfers/UpdateStatusMifos",
    Get:"FundTransfers/GetFundById",
    GetWaiting:"FundTransfers/GetFundWaitingById",
    GetSum:"FundTransfers/GetSumTotalNotApcept",
    GetListNotApcept:"FundTransfers/GetSumTotalNotApceptByUser"
  },
  //----------------------------FundReceive-----------------
  FundReceive: {
    List: "FundReceives/ListFundReceive",
    ExportExcel: "FundReceives/CreateExportFundReceive",
  },
  //----------------------------Category--------------------
  Category: {
    ListStatusFund: "Categories/ListCategoryStatus",
    ListMethodPopup: "Categories/ListCategoryMethodPopup",
    ListCategoryDenominationsMoney: "Categories/ListCategoryDenominationsMoney",
    ListMethod: "Categories/ListCategoryMethod",
    ListMethodCashBook: "Categories/ListCategoryMethodCashBook",
    ListRevError:"Categories/GetListErrorTran"
  },
  //----------------------------MasterData------------------
  MasterData: {
    ListBank: "master-data?TBL=RECEIVED_METHOD&PageSize=1000&PageNumber=1&Status=Active",
    ListPGD: "organizations?OrgType=12&PageSize=1000000&PageNumber=1",
    PGDNew: "organizations/company?Type=1,3&Code=1099",
  },
  //----------------------------SSO-------------------------
  SSO: {
    ListEmployee: "api/v1/Users/Search",
    ShopManagerByCode: "api/v1/UserProfile/GetShopManagerByCode",
    GetUSer: "api/v1/UserProfile/GetUsersByAttribute",
  },
  //----------------------------Mifos-----------------------
  Mifos: {
    GetFundBalance: "tellers/balance?tenantIdentifier=default&pretty=true",
    GetFundBalanceOffice: "tellers/officebalance?tenantIdentifier=default&pretty=true",
    CreateTransactionFund: "Mifos/CreateTransactionFund",
    UndoTransactionFund: "Mifos/UndoTransactionFund",
    ListCashBook: "Mifos/ListCashBook",
    CreateTran:"Mifos/CreateTransactionFundResend"
  },
  //-----------------------------InventorySec-----------------------
  InventorySec: {
    Create: "InventorySec/CreateInventorySec",
    Get: "InventorySec/ListInventorySec",
    Update: "InventorySec/UpdateInventorySecApporve",
    UploadImages: "InventorySec/UpdateUploadImages",
    ExportExcel: "InventorySec/CreateExportExcel",
    ListIsLast:"InventorySec/ListInventoryLastByShop",
    Export:"InventorySec/ExportTranWaitting",
    GetById:"InventorySec/GetById"
  },
  //-----------------------------FundTransferImage--------------------
  FundTransferImage: {
    List: "FundTransferImages/GetDetailByFund",
    Create: "FundTransferImages/CreateFundTransferImage",
  },
  //-----------------------------Los----------------------------------
  Los: {
    GetMoneyDifferenceByEmployee:
      "RevenueExpenditure/GetMoneyDifferenceByEmployee",
    GetMoneyAdvanceDifferenceByEmployee:
      "RevenueExpenditure/GetMoneyAdvanceDifferenceByEmployee",
    GetListRevPending:"RevenueExpenditure/GetListRevPending",
    GetListAdvancePending:"RevenueExpenditure/GetListAdvancePending",
    Export:"RevenueExpenditure/ExportListAdvance"
  },
  //---------------------------WithdrawSec----------------------------
  WithdrawSec: {
    List: "WithdrawSec/ListWithdrawSec",
    Create: "WithdrawSec/CreateWithdrawSec",
    Update: "WithdrawSec/UpdateWithdrawSecCancel",
    UpdateImage: "WithdrawSec/UpdateImage",
    ListImage: "WithdrawSec/GetListImage",
  },
  //----------------------------SQS Send Email--------------------------
  SQS: {
    SendMailFundTranfer: "198142843506/EMAILSMS_REQUEST",
  },
  //----------------------------CashBook--------------------------------
  CashBook: {
    ExportExcel: "CashBooks/ExportCashBook",
  },
});
const STATUS = Object.freeze({
  Activated: "Activated",
  Deactivated: "Deactivated",
  Deleted: "Deleted",
});
const ESTATEPRODUCT = Object.freeze({
  NotEffect: "NotEffect",
  Effect: "Effect",
  Expiry: "Expiry",
  All: "All",
});

const ESaleType = Object.freeze({
  Ban_kem: "Included",
  Ban_doc_lap: "Independently",
  San_pham_bo_tro: "Extra",
});
const EProductGroup = Object.freeze({
  All: "_1",
  TNDS: "1",
  BHCN: "2",
});

const MESSAGE = Object.freeze({
  Ok_Delete: "Xóa bản ghi thành công",
  Ok_Update_Partner: "Cập nhật Đối tác thành công",
  Ok_New_Partner: "Thêm Đối tác thành công",
  Update_Success: "Cập nhật thành công",
  Update_Error: "Cập nhật không thành công",
  Mifos_Error: "Xảy ra lỗi khi kết nối tới Mifos",
  MoneyOver: "Số tiền chuyển vượt quá quỹ!",
  TranYourSelf: "Bạn không thể chuyển quỹ cho chính mình!",
  MoneyZero: "Bạn chưa nhập số tiền chuyển!",
  ConfirmDeleteImage: "Bạn có chắc chắn xóa file ảnh: <br/> <b>",
  Ok_Insert: "Thêm mới thành công!",
  Ok_FundInsert:"Tạo lệnh chuyển quỹ thành công!"
});

const FORMMODE = Object.freeze({
  None: 0,
  Insert: 1,
  Update: 2,
  Delete: 3,
});

const ACTION = Object.freeze({
  All: "All",
  Read: "Read",
  Create: "Create",
  Update: "Update",
  Delete: "Delete",
  InActive: "InActive",
});

const IDENTITY_TYPE = Object.freeze({
  CMND: 1,
  CCCD: 2,
  HC: 3,
  GKS: 4,
  GPLX: 5,
  CMCA: 6,
});

const TRANSFER_TYPE = Object.freeze({
  SameOU: "01",
  DifferentOU: "02",
  All: "0",
});
const Method_Receive = Object.freeze({
  CashToCash: "01",
  CashToBank: "02",
  BankToBank: "03",
  BankToCash: "04",
  BankToWallet:"05",
  WalletToBank:"06",
  GPayToBank:"07"
});

const FUND_REASON_TYPE = Object.freeze({
  Excesses: "Excesses",
  Lack: "Lack",
});

const METHOD_TYPE = Object.freeze({
  HO: "01",
  PGD: "02",
});
const BUSINESS_TYPE = Object.freeze({
  CC: "CC",
  BH: "BH",
  TL: "TL",
  TC: "TC",
  CQ: "CQ",
  AO: "AO",
});
const TRANTYPEMIFOS = Object.freeze({
  Orther: {
    Tran: "73",
    Re: "74",
  },
  HoCashToBank: {
    Tran: "84",
    Re: "83",
  },
  HOCashToCash: {
    Tran: "92",
    Re: "93",
  },
  Withdraw: {
    Tran: "81",
    Re: "82",
  },
  Wallet:{
    Tran:"147",
    Re:"148"
  },
  GPay:{
    Tran:"150",
    Re:"149"
  }
});
const PAYMENT_TYPE = Object.freeze({
  Cash: "1",
  Bank: "2",
  Wallet:"4",
  GPay:"5"
});
const OFFICE = Object.freeze({
  HO: "1099",
});
const TRANSACTIONTYPEMIFOS = Object.freeze({
  Tran: "2",
  Re: "1",
});
const ROLEHO = Object.freeze({
  Code: "KD16",
});
const NAME_EXPORT = Object.freeze({
  Cash: "Sổ quỹ tiền mặt",
  Bank: "Sổ quỹ ngân hàng",
  EPay: "Sổ quỹ ví",
  GPay: "Sổ quỹ GPay",
});
const FUNDDETAILTYPE = Object.freeze({
  TRAN:"1",
  RECEIVE:"2"
});
export default {
  API,
  STATUS,
  ESTATEPRODUCT,
  MESSAGE,
  ESaleType,
  FORMMODE,
  IDENTITY_TYPE,
  ACTION,
  EProductGroup,
  TRANSFER_TYPE,
  Method_Receive,
  FUND_REASON_TYPE,
  METHOD_TYPE,
  BUSINESS_TYPE,
  SQSQUEUE_PartnerName,
  TRANTYPEMIFOS,
  PAYMENT_TYPE,
  OFFICE,
  TRANSACTIONTYPEMIFOS,
  ROLEHO,
  NAME_EXPORT,
  FUNDDETAILTYPE
};
</script>

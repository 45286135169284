<template>
  <section>
    <button onclick="ssoLogout(0)" ref="logout" style="display: none"></button>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div class="home" v-if="isMounted">
      <div class="columns is-gapless">
        <div class="main-menu">
          <NavMenu />
        </div>
        <div class="content">
          <header>
            <Information ref="info" />
          </header>
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.home {
  background-color: $color-white;
}
.content {
  width: 100%;
  background-color: $color-light-gray;
}

.header {
  padding: 20px;
  border-bottom: 1px solid #e8edf3;
  position: relative;
  background-color: $color-white;
  height: 80px;
  .logo {
    max-width: 215px;
    position: relative;
  }
}
</style>

<script>
import NavMenu from "./components/NavMenu.vue";
import UserService from "@/modules/user/services/UserService.vue";
import store from "@/store";
import ToastHelper from "@/helpers/OpenToast.vue";

export default {
  name: "DefaultLayout",
  components: {
    NavMenu,
  },
  data() {
    return {
      isLoading: true,
      isMounted: false,
    };
  },
  async mounted() {
    var _self = this;

    var loading = await setInterval(async () => {
      if (
        localStorage.getItem("AccessToken") !== null &&
        localStorage.getItem("AccessToken") !== "null"
      ) {
        clearInterval(loading);
        this.ontriggerReloadAnotherPage();

        var res = await UserService.getUserProfile();
        store.state.user = res;

        if (res.Profile.UserName != localStorage.getItem("username")) {
          this.removeItemLocalStorage();
        }

        localStorage.setItem("username", res.Profile.UserName);

        let positionOrganizationIndex = -1;

        for (let i = 0; i < res.PositionOrganization.length; i++) {
          let item = res.PositionOrganization[i];
          if (item) {
            if (item.Roles && item.Roles.length > 0) {
              positionOrganizationIndex = i;
              break;
            }
          }
        }

        if (positionOrganizationIndex == -1) {
          var logout = _self.$refs.logout;
          ToastHelper.OpenToast(
            "Bạn không có quyền truy cập hệ thống. Vui lòng liên hệ IT để được hỗ trợ",
            "is-danger"
          );
          setTimeout(() => {
            logout.click();
          }, 2000);
        }

        store.state.PositionOrganization = res.PositionOrganization;

        var shopSelected = null;
        if (
          localStorage.getItem("currentShop") == null ||
          localStorage.getItem("currentShop") == "null" ||
          localStorage.getItem("currentShop") == undefined ||
          localStorage.getItem("currentShop") == "undefined"
        )
          shopSelected = store.state.shopSelected =
            res.PositionOrganization[positionOrganizationIndex].Organization;
        else {
          shopSelected = JSON.parse(localStorage.getItem("currentShop"));
          var shopTemp = res.PositionOrganization.find(
            (x) => x.Organization.Code == shopSelected.Code
          );
          if (shopTemp) {
            store.state.shopSelected = shopTemp.Organization;
            store.state.positionSelected = shopTemp.Position;
          } else {
            store.state.shopSelected = res.PositionOrganization[positionOrganizationIndex].Organization;
            store.state.positionSelected = res.PositionOrganization[positionOrganizationIndex].Position;
          }
        }

        if (
          localStorage.getItem("currentRole") == null ||
          localStorage.getItem("currentRole") == "null" ||
          localStorage.getItem("currentRole") == undefined ||
          localStorage.getItem("currentRole") == "undefined"
        ) {
          store.state.roleSelected = res.PositionOrganization[positionOrganizationIndex].Roles[0];
        } else {
          var roleSelected = JSON.parse(localStorage.getItem("currentRole"));
          var posOrganization = res.PositionOrganization[positionOrganizationIndex];
          var shopTemp2 = res.PositionOrganization.find(
            (x) => x.Organization.Code == shopSelected.Code
          );
          if (shopTemp2) {
            posOrganization = shopTemp2;
          }
          var roleTemp = posOrganization.Roles.find(
            (x) => x.Code == roleSelected.Code
          );
          if (roleTemp) {
            store.state.roleSelected = roleTemp;
          } else {
            store.state.roleSelected = res.PositionOrganization[positionOrganizationIndex].Roles[0];
          }
        }

        localStorage.setItem(
          "currentShop",
          JSON.stringify(store.state.shopSelected)
        );
        localStorage.setItem(
          "currentRole",
          JSON.stringify(store.state.roleSelected)
        );

        var organizations = [store.state.shopSelected.Code];

        store.state.actions = await UserService.getActions(store.state.roleSelected.Code);

        let request = {
          OuIds: organizations,
        };
        var shops = await UserService.getListTransaction(request);
        store.state.shops = shops.Data;
        _self.isMounted = true;
        _self.isLoading = false;
      }
    }, 500);
  },
  methods: {
    removeItemLocalStorage() {
      localStorage.removeItem("currentShop");
      localStorage.removeItem("currentRole");
    },
    ontriggerReloadAnotherPage() {
      window.addEventListener('storage', function(event){
        if ((event.key == 'username' && event.newValue != event.oldValue) || event.key == null) { 
            window.location.reload();
        }
      });
    }
  },
  watch: {
    "$store.state.isLoading": function (newVal) {
      var _self = this;
      _self.isLoading = newVal;
    },
  },
};
</script>

import { extend } from "vee-validate";
import ConvertDatetime from "@/helpers/ConvertDatetime.vue";
import * as moment from "moment";

const dateFormat = "DD/MM/YYYY";

extend("phone", {
  message: "{_field_} sai định dạng.",
  validate: value => {
    const phoneFormat = new RegExp(`(0[3|5|7|8|9])+([0-9]{8})`);
    return phoneFormat.test(value);
  }
});

extend("adult", {
  params: ["args"],
  message: "Phải trên {args} tuổi.",
  validate: (value, { args }) => {
    var age = ConvertDatetime.GetDiffDate(value, "years");
    return age >= args;
  }
});

extend("charAndNumber", {
  message: "{_field_} chỉ chứa các kí tự số và chữ không dấu.",
  validate: value => {
    const charNumberFormat = new RegExp("^[0-9a-zA-Z]+$");
    return charNumberFormat.test(value);
  }
});

extend("pattern", {
  params: ["patternExp"],
  message: "{_field_} sai định dạng",
  validate: (value, { patternExp }) => {
    const regFomart = new RegExp(patternExp);
    return regFomart.test(value);
  }
});

extend("forbidCharacters", {
  params: ["listForbid"],
  message: `{_field_} không được chứa các kí tự {listForbid}`,
  validate: (value, { listForbid }) => {
    if (listForbid) {
      var splitted = listForbid.split("");
      for (let index = 0; index < splitted.length; index++) {
        if (value.includes(splitted[index])) {
          return false;
        }
      }
    }
    return true;
  }
});

extend("checkMinDate", {
  params: ["minDate", "errorMessage", "includeToday"],
  validate: (value, { minDate, includeToday }) => {
    minDate = moment(minDate, dateFormat).startOf("day");
    value = moment(value, dateFormat).startOf("day");
    if (!includeToday) return value.isAfter(minDate);
    return value.isSameOrAfter(minDate);
  },
  message: "{errorMessage}"
});

extend("checkMaxDate", {
  params: ["includeToday", "errorMessage"],
  validate: (value, { currentDate, includeToday }) => {
    currentDate = moment(new Date(), dateFormat).startOf("day");
    value = moment(value, dateFormat).startOf("day");
    if (!includeToday) return value.isBefore(currentDate);
    return value.isSameOrBefore(currentDate);
  },
  message: "{errorMessage}"
});

<template>
  <div class="sidebar-page">
    <section class="sidebar-layout">
      <b-sidebar
        position="static"
        :reduce="reduce"
        open
        :fullheight="fullheight"
        type="is-white"
      >
        <div class="logo">
          <img src="@/assets/img/logo.svg" />

          <img
            src="@/assets/img/expand.svg"
            alt=""
            @click="reduce = !reduce"
            class="right"
          />
        </div>
        <!-- <div class="new-contact">
          <router-link to="/hop-dong/chon-san-pham">
            <b-button type="is-success" icon-left="heart-plus-outline" expanded>
              Tạo hợp đồng
            </b-button>
          </router-link>
        </div> -->
        <b-menu class="is-custom-mobile">
          <b-menu-list>
            <b-menu-item tag="router-link" to="/dashboard">
              <template #label="">
                <b-icon icon="checkbox-multiple-blank"></b-icon>
                <span class="menu-text">Dashboard</span>
              </template>
            </b-menu-item>
            <b-menu-item
              :expanded="checkActive('/quan-ly-quy')"
              :active="checkActive('/quan-ly-quy')"
            >
              <template #label="props">
                <b-icon icon="cogs"></b-icon>
                <span class="menu-text">Quỹ</span>
                <b-icon
                  class="right"
                  :icon="props.expanded ? 'chevron-down' : 'chevron-up'"
                ></b-icon>
              </template>
              <b-menu-item
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundTransfer,
                    $getConst('ACTION').FundTransfer.List
                  )
                "
                icon="handshake"
                label="Chuyển quỹ"
                tag="router-link"
                to="/quan-ly-quy/danh-sach-chuyen-quy"
                :class="{
                  'route-active': checkActive(
                    '/quan-ly-quy/danh-sach-chuyen-quy'
                  ),
                }"
              ></b-menu-item>
              <b-menu-item
                  v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundReceive,
                    $getConst('ACTION').FundReceive.List
                  )
                "
                icon="handshake"
                label="Nhận quỹ"
                tag="router-link"
                to="/quan-ly-quy/danh-sach-nhan-quy"
                :class="{
                  'route-active': checkActive(
                    '/quan-ly-quy/danh-sach-nhan-quy'
                  ),
                }"
              ></b-menu-item>

              <b-menu-item
                 v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundInventorySec,
                    $getConst('ACTION').FundInventorySec.Create
                  )
                "
                icon="handshake"
                label="Kiểm kê quỹ và séc"
                tag="router-link"
                to="/quan-ly-quy/tao-kiem-ke-quy-sec"
                :class="{
                  'route-active': checkActive(
                    '/quan-ly-quy/tao-kiem-ke-quy-sec'
                  ),
                }"
              ></b-menu-item>

              <b-menu-item
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundInventorySec,
                    $getConst('ACTION').FundInventorySec.List
                  )
                "
                icon="handshake"
                label="Danh sách kiểm kê"
                tag="router-link"
                to="/quan-ly-quy/danh-sach-kiem-ke"
                :class="{
                  'route-active': checkActive('/quan-ly-quy/danh-sach-kiem-ke'),
                }"
              ></b-menu-item>

              <b-menu-item
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').WithdrawSec,
                    $getConst('ACTION').WithdrawSec.List
                  )
                "
                icon="handshake"
                label="Rút séc nhập quỹ"
                tag="router-link"
                to="/quan-ly-quy/danh-sach-rut-sec-nhap-quy"
                :class="{
                  'route-active': checkActive(
                    '/quan-ly-quy/danh-sach-rut-sec-nhap-quy'
                  ),
                }"
              ></b-menu-item>
                <b-menu-item
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundInventorySec,
                    $getConst('ACTION').FundInventorySec.ListRev
                  )
                "
                icon="handshake"
                label="Tạm ứng chưa hoàn ứng hết"
                tag="router-link"
                to="/quan-ly-quy/danh-sach-cho-xu-ly"
                :class="{
                  'route-active': checkActive('/quan-ly-quy/danh-sach-cho-xu-ly'),
                }"
              ></b-menu-item>
          
            </b-menu-item>
            <b-menu-item
                       :expanded="checkActive('/quan-ly-so-quy')"
                        :active="checkActive('/quan-ly-so-quy')"
            >
              <template #label="props">
                <b-icon icon="cogs"></b-icon>
                <span class="menu-text">Sổ quỹ</span>
                <b-icon
                  class="right"
                  :icon="props.expanded ? 'chevron-down' : 'chevron-up'"
                ></b-icon>
              </template>
                   <b-menu-item
                    v-if="
                  checkAccessRole(
                    $getConst('MODULE').Mifos,
                    $getConst('ACTION').CashBook.ListBank
                  )
                "
                icon="handshake"
                label="Sổ quỹ Agent"
                tag="router-link"
                to="/quan-ly-so-quy/so-quy-agent"
                :class="{
                  'route-active': checkActive('/quan-ly-so-quy/so-quy-agent'),
                }"
              ></b-menu-item>
                <b-menu-item
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').Mifos,
                    $getConst('ACTION').CashBook.List
                  )
                "
                icon="handshake"
                label="Sổ quỹ tiền mặt"
                tag="router-link"
                to="/quan-ly-so-quy/so-quy-tien-mat"
                :class="{
                  'route-active': checkActive('/quan-ly-so-quy/so-quy-tien-mat'),
                }"
              ></b-menu-item>
              <b-menu-item
                  v-if="
                  checkAccessRole(
                    $getConst('MODULE').Mifos,
                    $getConst('ACTION').CashBook.ListBank
                  )
                "
                icon="handshake"
                label="Sổ quỹ ngân hàng"
                tag="router-link"
                to="/quan-ly-so-quy/so-quy-ngan-hang"
                :class="{
                  'route-active': checkActive('/quan-ly-so-quy/so-quy-ngan-hang'),
                }"
              ></b-menu-item>
                  <b-menu-item
                    v-if="
                  checkAccessRole(
                    $getConst('MODULE').Mifos,
                    $getConst('ACTION').CashBook.ListBank
                  )
                "
                icon="handshake"
                label="Sổ quỹ ví"
                tag="router-link"
                to="/quan-ly-so-quy/so-quy-vi-dien-tu"
                :class="{
                  'route-active': checkActive('/quan-ly-so-quy/so-quy-vi-dien-tu'),
                }"
              ></b-menu-item>
              <b-menu-item
                    v-if="
                  checkAccessRole(
                    $getConst('MODULE').Mifos,
                    $getConst('ACTION').CashBook.ListBank
                  )
                "
                icon="handshake"
                label="Sổ quỹ GPay-CIMB"
                tag="router-link"
                to="/quan-ly-so-quy/so-quy-gpay-cimb"
                :class="{
                  'route-active': checkActive('/quan-ly-so-quy/so-quy-gpay-cimb'),
                }"
              ></b-menu-item>
            </b-menu-item>
          </b-menu-list>
        </b-menu>
      </b-sidebar>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reduce: false,
      fullheight: true,
    };
  },
  methods: {
    checkActive(path) {
      return this.$route.path.indexOf(path) === 0;
    },
  },
};
</script>

<style lang="scss">
.menu {
  font-size: 14px !important;
}
.logo {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 24px 20px;
  box-shadow: 0 4px 2px -2px #f6f6f6;

  .right {
    margin-left: auto;
    order: 2;
    cursor: pointer;
  }
}
.menu-list {
  padding: 20px;
  a {
    padding: 1em 0.75em !important;
    &.is-active {
      background-color: $color-light-green !important;
      color: #00833e !important;
      font-weight: bold;
      border-radius: 4px;
    }
  }
  > li {
    > a {
      padding: 15px 11px;
      display: flex;
      align-items: center;
      span.menu-text {
        margin-left: 10px;
      }

      .right {
        margin-left: auto;
        order: 2;
      }
    }

    ul {
      border-left: none !important;
      margin: 0 !important;
      padding-left: 1em !important;
    }
  }
}

.new-contact {
  margin-bottom: 15px;
  a {
    background-color: $color-default;
    color: $color-white !important;
    border-radius: 5px !important;
    font-size: 18px;
    padding: 13px 11px !important;
  }
}

.route-active a,
.route-active a:hover {
  color: $color-default;
  // background-color: $color-light-green !important;
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
  }
}
:b-deep .sidebar-content {
  width: 294px !important;
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import ConstPlugin from "@/helpers/ConstPlugin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/modules/dashboard/views/Dashboard.vue")
  },

  {
    path: "*",
    redirect: "/dashboard"
    // meta: { layout: "blank" },
    // component: () => import("@/modules/dashboard/views/Dashboard.vue"),
  },
  {
    path: ConstPlugin.ROUTER.FundTransfer.List,
    name: "ListFundTransfer",
    component: () => import("@/modules/fundtransfers/views/FundTransfer.vue"),
    meta: { module: ConstPlugin.MODULE.FundTransfer, action: ConstPlugin.ACTION.FundTransfer.List },
    props: (route) => ({
      Offset: Number(route.query.offset) || 0,
      Limit: Number(route.query.limit) || 10,
      Search: route.query.Search || ""
    })
  },
  {
    path: ConstPlugin.ROUTER.WithdrawSec.List,
    name: "ListWithdrawSec",
    component: () => import("@/modules/withdrawsecs/views/WithdrawSecList.vue"),
    meta: { module: ConstPlugin.MODULE.WithdrawSec, action: ConstPlugin.ACTION.WithdrawSec.List },
    props: (route) => ({
      Offset: Number(route.query.offset) || 0,
      Limit: Number(route.query.limit) || 10,
      Search: route.query.Search || ""
    })
  },
  {
    path: ConstPlugin.ROUTER.CashBook.List,
    name: "ListCashBook",
    meta: { module: ConstPlugin.MODULE.Mifos, action: ConstPlugin.ACTION.CashBook.List },
    component: () => import("@/modules/cashbooks/views/CashBookList.vue"),
  },
  {
    path: ConstPlugin.ROUTER.CashBook.ListBank,
    name: "ListCashBookBank",
    meta: { module: ConstPlugin.MODULE.Mifos, action: ConstPlugin.ACTION.CashBook.ListBank },
    component: () => import("@/modules/cashbooks/views/CashBookBankList.vue"),
  },
  {
    path: ConstPlugin.ROUTER.CashBook.ListWallet,
    name: "ListCashBookWallet",
    meta: { module: ConstPlugin.MODULE.Mifos, action: ConstPlugin.ACTION.CashBook.ListWallet },
    component: () => import("@/modules/cashbooks/views/CashBookWallet.vue"),
  },
  {
    path: ConstPlugin.ROUTER.CashBook.ListGpay,
    name: "ListGPayBook",
    meta: { module: ConstPlugin.MODULE.Mifos, action: ConstPlugin.ACTION.CashBook.ListGpay },
    component: () => import("@/modules/cashbooks/views/GPayBook.vue"),
  },
  {
    path: ConstPlugin.ROUTER.CashBook.ListAgent,
    name: "ListAgentBook",
    meta: { module: ConstPlugin.MODULE.Mifos, action: ConstPlugin.ACTION.CashBook.ListAgent },
    component: () => import("@/modules/cashbooks/views/AgentBook.vue"),
  },
  {
    path: ConstPlugin.ROUTER.FundReceive.List,
    name: "FundReceiveList",
    meta: { module: ConstPlugin.MODULE.FundReceive, action: ConstPlugin.ACTION.FundReceive.List },
    component: () => import("@/modules/fundreceives/views/FundReceiveList.vue")
  },
  {
    path: ConstPlugin.ROUTER.FundInventorySec.Create,
    name: "FundInventorySecCreate",
    meta: { module: ConstPlugin.MODULE.FundInventorySec, action: ConstPlugin.ACTION.FundInventorySec.Create },
    component: () => import("@/modules/fundinventorysec/views/FundInventorySec.vue")
  },
  {
    path: ConstPlugin.ROUTER.FundInventorySec.List,
    name: "FundInventorySecList",
    meta: { module: ConstPlugin.MODULE.FundInventorySec, action: ConstPlugin.ACTION.FundInventorySec.List },
    component: () => import("@/modules/fundinventorysec/views/FundInventorySecList.vue")
  },
  {
    path: ConstPlugin.ROUTER.FundInventorySec.ListRev,
    name: "FundInventoryPending",
    meta: { module: ConstPlugin.MODULE.FundInventorySec, action: ConstPlugin.ACTION.FundInventorySec.ListRev },
    component: () => import("@/modules/fundinventorysec/views/RevenueExpenditurePending.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

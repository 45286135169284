import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import Buefy from "buefy";
import 'buefy/dist/buefy.css'
import Default from "./layouts/Default.vue";
import PagingItem from "@/modules/main/components/PagingItem.vue";
import Information from "@/layouts/components/Information.vue";
import "@/assets/css/site.scss";
import "@/assets/css/default.scss";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import VueCurrencyInput from "vue-currency-input";
import ConstPlugin from "@/helpers/ConstPlugin.vue";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueHtml2pdf from 'vue-html2pdf';

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from "vee-validate";
import vi from "vee-validate/dist/locale/vi.json";
import * as rules from "vee-validate/dist/rules";
import "./helpers/VeeValidate.js";
import "viewerjs/dist/viewer.css";
import VueViewer from 'v-viewer';
const pluginOptions = {
  /* see config reference */
  globalOptions: {
    currency: null,
    distractionFree: {
      hideNegligibleDecimalDigits: true,
      hideCurrencySymbol: true,
      hideGroupingSymbol: false
    },
    locale: "de",
    allowNegative: false
  }
};
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(ConstPlugin);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=no", "scrollbars=yes"],
  styles: [
    "https://cdn.jsdelivr.net/npm/bulma@0.9.1/css/bulma.min.css",
    "/print.css",
  ],
};

Vue.use(VueHtmlToPaper,options);
Vue.use(VueHtml2pdf);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("vi", vi);

Vue.use(Buefy);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("PagingItem", PagingItem);
Vue.component("Information", Information);
Vue.component("v-select", vSelect);
Vue.component("default-layout", Default);
Vue.use(VueViewer)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  mixin,
  render: (h) => h(App),
}).$mount("#app");

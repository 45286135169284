<script>
import { ToastProgrammatic as Toast } from "buefy";

const OpenToast = (message, type) => {
  Toast.open({
    duration: 5000,
    message: message,
    position: "is-bottom",
    type: type
  });
};

export default {
  OpenToast
};
</script>

<template>
  <div class="modal-content">
    <div class="modal-main">
      <div class="divflex align-items-center justify-content-between mb-4">
        <p class="modal-title">Đổi quyền truy cập</p>
        <div @click="$emit('close')">
          <b-icon icon="close" style="cursor:pointer"></b-icon>
        </div>
      </div>
      <div class="list-pos">
        <template v-for="(pos, idx) in user.PositionOrganization">
          <div :key="idx">
            <div class="org-name mb-4">{{ pos.Organization ? pos.Organization.Name : "" }}</div>
            <template v-for="(role, idx2) in pos.Roles">
              <div :key="idx2">
                <div class="pt-2 pb-2 pl-4">
                  <b-radio
                    name="name"
                    v-model="selectedRole"
                    :native-value="idx + '' + idx2"
                    @input="selectRole(role, pos.Organization)"
                  >
                    {{ role.Name }}
                  </b-radio>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <b-button class="confirm-change mt-4" @click="confirmChange()"
        >Đổi quyền truy cập</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["shopSelected", "roleSelected", "user"],
  data() {
    return {
      selectedRole: null,
      selectedOrganization: null,
    };
  },
  mounted() {
    var _self = this;
    if (_self.user) {
      let organizationIndex = _self.user.PositionOrganization.findIndex(
        (x) => x.Organization.Code == _self.shopSelected.Code && x.Roles.some(r => r.Code == _self.roleSelected.Code)
      );

      let roles = _self.user.PositionOrganization[organizationIndex].Roles;
      let roleIndex = roles.findIndex(
        (x) => x.Code == _self.roleSelected.Code
      );

      _self.selectedRole = roleIndex != -1 ? `${organizationIndex}${roleIndex}` : null

      // var tmpShop = _self.user.PositionOrganization.find(
      //   (x) => x.Organization.Code == _self.shopSelected.Code
      // );
      // if (tmpShop) {
      //   var tmpShopIndex = _self.user.PositionOrganization.findIndex(
      //     (x) => x.Organization.Code == _self.shopSelected.Code
      //   );
      //   var tmpRoleIndex = tmpShop.Roles.findIndex(
      //     (x) => x.Code == _self.roleSelected.Code
      //   );
      //   _self.selectedRole =
      //     tmpRoleIndex != -1 ? `${tmpShopIndex}${tmpRoleIndex}` : null;
      // }
    }
  },
  methods: {
    selectRole(role, organization) {
      var _self = this;
      _self.selectedRole = role;
      _self.selectedOrganization = organization;
    },
    confirmChange() {
      var _self = this;
      _self.$emit("selectRole", {
        Role: _self.selectedRole,
        Organization: _self.selectedOrganization,
      });
      _self.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.divflex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.modal-content {
  width: 100% !important;
  .modal-main {
    width: 500px !important;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    .modal-title {
      font-size: 14px;
      color: #979797;
      text-transform: uppercase;
    }
    .org-name {
      font-size: 14px;
      padding: 10px 0px;
      border-bottom: 1px solid #00833e;
    }
    .list-pos {
      max-height: 600px;
      overflow: auto;
    }
  }
}
.confirm-change {
  background: linear-gradient(90deg, #00833e 52.54%, #007d67 100%);
  border-radius: 100px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: $color-white;
}
</style>

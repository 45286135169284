<template>
  <section v-if="user">
    <button onclick="ssoLogout(0)" ref="logout" style="display: none"></button>
    <div class="shop-list" v-if="shopSelected">
      <b-icon icon="map-marker-outline"></b-icon>
      <div>{{ shopSelected.Name }}</div>
    </div>
    <div class="user-info">
      <div class="systems">
        <b-dropdown position="is-bottom-left">
          <template #trigger>
            <b-tooltip
              position="is-left"
              label="Chuyển đổi hệ thống"
              type="is-dark"
            >
              <b-icon icon="vector-circle"></b-icon>
            </b-tooltip>
          </template>
          <div class="columns is-gapless is-multiline">
            <div
              class="column is-6"
              v-for="(system, idx) in systems"
              :key="idx"
            >
              <a class="system-item" :href="system.Value" target="_blank">
                <p></p>
                {{ system.Code }}
              </a>
            </div>
          </div>
        </b-dropdown>
      </div>
      <div class="profile" v-if="user.Profile">
        <b-navbar-dropdown :right="right">
          <template #label>
            <div class="profile">
              <div class="avatar" :style="{ backgroundColor: randomColor() }">
                {{ getShortName() }}
              </div>
              <div class="infor">
                <div>
                  {{ user.Profile.FirstName + " " + user.Profile.LastName }}
                </div>
                <p>
                  {{ roleSelected.Name }}
                </p>
              </div>
            </div>
          </template>
          <!-- <b-navbar-item>
            Tài khoản
          </b-navbar-item> -->
          <b-navbar-item
            href="#"
            v-if="poList && poList.length > 1"
            @click="changeRole"
            ><b-icon icon="cached"></b-icon> Đổi quyền truy cập
          </b-navbar-item>
          <b-navbar-item :href="urlChangePassword">
            <img src="@/assets/img/change_pass.svg" alt="" /> Đổi mật khẩu
          </b-navbar-item>
          <b-navbar-item @click="onLogout()">
            <img src="@/assets/img/logout.svg" alt="" /> Đăng xuất
          </b-navbar-item>
        </b-navbar-dropdown>
      </div>
    </div>
  </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import RoleModal from "@/layouts/components/RoleModal.vue";
import UserService from "@/modules/user/services/UserService.vue";
import store from "@/store";

var urlSSO = process.env.VUE_APP_URLSSO;

export default {
  data() {
    return {
      right: true,
      user: { roles: [] },
      uuid: uuidv4(),
      roleList: [],
      roleSelected: {},
      shopList: [],
      poList: [],
      shopSelected: {},
      urlChangePassword: `${urlSSO}/change-password?token=${localStorage.getItem(
        "AccessToken"
      )}&redirect=${window.location.href}`,
      isDis: false,
      systems: [
      ],
    };
  },
  async mounted() {
    var _self = this;
    _self.user = store.state.user;
    _self.roleSelected = store.state.roleSelected;
    _self.shopSelected = store.state.shopSelected;
    _self.poList = store.state.PositionOrganization;
    _self.systems = (await UserService.getSystems()).Data;
  },
  methods: {
    onLogout() {
      localStorage.clear();
      var logout = this.$refs.logout;
      logout.click();
    },
    randomColor() {
      var _self = this;
      var idx = 0;
      let str = "ABCDĐEFGHIJKLMNOPQRSTUVWXYZ";
      let arr = ["#FF4444", "#456BFF", "#FF7E00", "#313D4F"];
      if (_self.user.profile) {
        var fullName =
          _self.user.profile.firstname + " " + _self.user.profile.lastname;
        let firstChar = fullName.slice(0, 1).toUpperCase();
        idx = str.indexOf(firstChar) % arr.length;
      }

      return arr[idx];
    },
    getShortName() {
      var _self = this;
      if (_self.user.profile) {
        var fullName =
          _self.user.profile.firstname + " " + _self.user.profile.lastname;
        var name = fullName.split(" ");
        return (
          name[0].slice(0, 1).toUpperCase() +
          name[name.length - 1].slice(0, 1).toUpperCase()
        );
      }
    },
    selectShop(shop) {
      localStorage.setItem("currentShop", JSON.stringify(shop));
      this.$router.go();
    },
    changeRole() {
      var _self = this;
      _self.$buefy.modal.open({
        parent: _self,
        component: RoleModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          shopSelected: _self.shopSelected,
          roleSelected: _self.roleSelected,
          user: _self.user,
        },
        events: {
          selectRole: _self.selectRole,
        },
      });
    },
    async selectRole(data) {
      var _self = this;
      _self.roleSelected = data.Role;
      _self.shopSelected = data.Organization;

      localStorage.setItem("currentRole", JSON.stringify(data.Role));
      localStorage.setItem("currentShop", JSON.stringify(data.Organization));

      _self.$router.go();
    },
    getPositionByCode(code) {
      var result = this.roleList.filter((item) => item.Code == code)[0].Name;
      return result;
    },
  },
};
</script>

<script>
import moment from "moment";

const ConvertDatetimeToUnix = date => {
  return parseInt(moment(date).format("X"));
};

const ConvertUnixToMonth = number => {
  return number ? moment.unix(number).format("MM, YYYY") : null;
};

const ConvertUnixToFormat = (number, format) => {
  return number ? moment.unix(number).format(format) : null;
};

const ConvertDatetimeToFormat = (date, format) => {
  return moment(date).format(format);
};

const ConvertUnixToDate = number => {
  return number ? moment.unix(number).format("DD/MM/YYYY") : null;
};

const ConvertUnixToDatetime = number => {
  return number ? moment.unix(number).format("DD/MM/YYYY HH:mm") : null;
};
const ConvertDateToStringDatetime = date => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};
const ConvertDateToString = (date) => {
  return date ? moment(String(date)).format("DD/MM/YYYY") : null;
};
const ConvertDateToString2 = (date) => {
  return date ? moment(String(date)).format("YYYY-MM-DD") : null;
};
const ConvertUnixToDateFormat = number => {
  return number ? moment.unix(number).toDate() : null;
};

const ConvertBeginDatetimeToUnix = date => {
  let beginDate = moment(date).set({ hour: 0, minute: 0, second: 0 });
  return ConvertDatetimeToUnix(beginDate);
};

const ConvertEndDatetimeToUnix = date => {
  let endDate = moment(date).set({ hour: 23, minute: 59, second: 59 });
  return ConvertDatetimeToUnix(endDate);
};

const ConvertBeginDatetime = date => {
  return moment(date)
    .set({ hour: 0, minute: 0, second: 0 })
    .toDate();
};

const ConvertEndDatetime = date => {
  return moment(date)
    .set({ hour: 23, minute: 59, second: 59 })
    .toDate();
};

const ConvertBeginMonthDatetimeToUnix = date => {
  let beginDate = moment(date).startOf("month");
  return ConvertDatetimeToUnix(beginDate);
};

const ConvertEndMonthDatetimeToUnix = date => {
  let endDate = moment(date).endOf("month");
  return ConvertDatetimeToUnix(endDate);
};

const ConvertBeginCurrentMonthToDatetime = () => {
  return moment()
    .startOf("month")
    .toDate();
};

const ConvertEndCurrentMonthToDatetime = () => {
  return moment()
    .endOf("month")
    .toDate();
};

const SubtractDatetime = (date, number) => {
  return moment(date)
    .subtract(number, "days")
    .toDate();
};

const AddDatetime = (date, number) => {
  return moment(date)
    .add(number, "days")
    .toDate();
};

const SubtractMonth = (date, number) => {
  return moment(date)
    .subtract(number, "months")
    .toDate();
};

const AddMonth = (date, number) => {
  return moment(date)
    .add(number, "months")
    .toDate();
};

const StartOfMonth = date => {
  return moment(date).startOf("month");
};

const TotalDayInMonth = number => {
  return moment.unix(number).daysInMonth();
};

const ParseStringToFormat = (str, format) => {
  return moment(str, "YYYYMMDDhhmmss").format(format);
};

const ParseNumberToHour = number => {
  var hour = number / 3600;
  var minus = (number % 3600) / 60;
  var date = new Date();
  let beginDate = moment(date).set({ hour: hour, minute: minus, second: 0 });
  return beginDate.toDate();
};

const ParseNumberToString = number => {
  var hour = Math.floor(number / 3600);
  var minus = (number % 3600) / 60;
  let beginDate = hour + ":" + minus;
  return beginDate;
};

const ParseTimeToSecond = date => {
  return moment(date).hour() * 3600 + moment(date).minutes() * 60;
};

const ConvertNetDatetimeToDatetime = dt => {
  return moment(dt).toDate();
};

const GetDiffDate = (date, format) => {
  return moment().diff(date, format);
};

const AddHours = (date, number) => {
  return moment(date)
    .add(number, "hours")
    .format("DD/MM/YYYY HH:mm");
};

const CalcularDate=(dateEnd,dateStart)=>
{
  return moment.utc(moment(dateEnd,"YYYY-MM-DD").diff(moment(dateStart,"YYYY-MM-DD"))).format("DD");
}
export default {
  ConvertDatetimeToUnix,
  ConvertUnixToMonth,
  ConvertUnixToFormat,
  ConvertBeginDatetimeToUnix,
  ConvertEndDatetimeToUnix,
  ConvertBeginDatetime,
  ConvertEndDatetime,
  ConvertUnixToDate,
  ConvertUnixToDatetime,
  ConvertUnixToDateFormat,
  ConvertDateToStringDatetime,
  ConvertBeginMonthDatetimeToUnix,
  ConvertEndMonthDatetimeToUnix,
  ConvertBeginCurrentMonthToDatetime,
  ConvertEndCurrentMonthToDatetime,
  SubtractDatetime,
  AddDatetime,
  SubtractMonth,
  AddMonth,
  StartOfMonth,
  TotalDayInMonth,
  ParseStringToFormat,
  ParseNumberToHour,
  ParseTimeToSecond,
  ParseNumberToString,
  ConvertDatetimeToFormat,
  ConvertNetDatetimeToDatetime,
  GetDiffDate,
  ConvertDateToString,
  ConvertDateToString2,
  AddHours,
  CalcularDate
};
</script>

<script>
import Client from "./AxiosClient";
import UserService from "@/modules/user/services/UserService.vue";

const get = url => {
  let encodedCodeKey = UserService.encodedSCodeHeader();
  return Client.get(`${url}`, { headers: { EncodedSCode: encodedCodeKey } });
};

const post = (url, obj) => {
  let encodedCodeKey = UserService.encodedSCodeHeader();
  return Client.post(`${url}`, obj, {
    headers: { EncodedSCode: encodedCodeKey }
  });
};

const postDownload = (url, obj) => {
  let encodedCodeKey = UserService.encodedSCodeHeader();
  return Client.post(
    `${url}`,
    obj,
    { responseType: "arraybuffer" },
    { headers: { EncodedSCode: encodedCodeKey } }
  );
};
const getBase64 = async(url)  => {
  let encodedCodeKey = UserService.encodedSCodeHeader();
  let result = await Client.get(`${url}`, { headers: { EncodedSCode: encodedCodeKey }, responseType: 'arraybuffer' });
  let raw = Buffer.from(result).toString('base64');
  return `data:*;base64,${raw}`;
};
const update = (url, obj) => {
  let encodedCodeKey = UserService.encodedSCodeHeader();
  return Client.put(`${url}`, obj, {
    headers: { EncodedSCode: encodedCodeKey }
  });
};

const deleted = (url, obj) => {
  let encodedCodeKey = UserService.encodedSCodeHeader();
  return Client.delete(
    `${url}`,
    { data: obj },
    { headers: { EncodedSCode: encodedCodeKey } }
  );
};

export default {
  get,
  post,
  update,
  deleted,
  postDownload,
  getBase64
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-page"},[_c('section',{staticClass:"sidebar-layout"},[_c('b-sidebar',{attrs:{"position":"static","reduce":_vm.reduce,"open":"","fullheight":_vm.fullheight,"type":"is-white"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/img/logo.svg")}}),_c('img',{staticClass:"right",attrs:{"src":require("@/assets/img/expand.svg"),"alt":""},on:{"click":function($event){_vm.reduce = !_vm.reduce}}})]),_c('b-menu',{staticClass:"is-custom-mobile"},[_c('b-menu-list',[_c('b-menu-item',{attrs:{"tag":"router-link","to":"/dashboard"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-icon',{attrs:{"icon":"checkbox-multiple-blank"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])]},proxy:true}])}),_c('b-menu-item',{attrs:{"expanded":_vm.checkActive('/quan-ly-quy'),"active":_vm.checkActive('/quan-ly-quy')},scopedSlots:_vm._u([{key:"label",fn:function(props){return [_c('b-icon',{attrs:{"icon":"cogs"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Quỹ")]),_c('b-icon',{staticClass:"right",attrs:{"icon":props.expanded ? 'chevron-down' : 'chevron-up'}})]}}])},[(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').FundTransfer,
                  _vm.$getConst('ACTION').FundTransfer.List
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive(
                  '/quan-ly-quy/danh-sach-chuyen-quy'
                ),
              },attrs:{"icon":"handshake","label":"Chuyển quỹ","tag":"router-link","to":"/quan-ly-quy/danh-sach-chuyen-quy"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').FundReceive,
                  _vm.$getConst('ACTION').FundReceive.List
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive(
                  '/quan-ly-quy/danh-sach-nhan-quy'
                ),
              },attrs:{"icon":"handshake","label":"Nhận quỹ","tag":"router-link","to":"/quan-ly-quy/danh-sach-nhan-quy"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').FundInventorySec,
                  _vm.$getConst('ACTION').FundInventorySec.Create
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive(
                  '/quan-ly-quy/tao-kiem-ke-quy-sec'
                ),
              },attrs:{"icon":"handshake","label":"Kiểm kê quỹ và séc","tag":"router-link","to":"/quan-ly-quy/tao-kiem-ke-quy-sec"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').FundInventorySec,
                  _vm.$getConst('ACTION').FundInventorySec.List
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive('/quan-ly-quy/danh-sach-kiem-ke'),
              },attrs:{"icon":"handshake","label":"Danh sách kiểm kê","tag":"router-link","to":"/quan-ly-quy/danh-sach-kiem-ke"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').WithdrawSec,
                  _vm.$getConst('ACTION').WithdrawSec.List
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive(
                  '/quan-ly-quy/danh-sach-rut-sec-nhap-quy'
                ),
              },attrs:{"icon":"handshake","label":"Rút séc nhập quỹ","tag":"router-link","to":"/quan-ly-quy/danh-sach-rut-sec-nhap-quy"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').FundInventorySec,
                  _vm.$getConst('ACTION').FundInventorySec.ListRev
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive('/quan-ly-quy/danh-sach-cho-xu-ly'),
              },attrs:{"icon":"handshake","label":"Tạm ứng chưa hoàn ứng hết","tag":"router-link","to":"/quan-ly-quy/danh-sach-cho-xu-ly"}}):_vm._e()],1),_c('b-menu-item',{attrs:{"expanded":_vm.checkActive('/quan-ly-so-quy'),"active":_vm.checkActive('/quan-ly-so-quy')},scopedSlots:_vm._u([{key:"label",fn:function(props){return [_c('b-icon',{attrs:{"icon":"cogs"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Sổ quỹ")]),_c('b-icon',{staticClass:"right",attrs:{"icon":props.expanded ? 'chevron-down' : 'chevron-up'}})]}}])},[(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').Mifos,
                  _vm.$getConst('ACTION').CashBook.ListBank
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive('/quan-ly-so-quy/so-quy-agent'),
              },attrs:{"icon":"handshake","label":"Sổ quỹ Agent","tag":"router-link","to":"/quan-ly-so-quy/so-quy-agent"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').Mifos,
                  _vm.$getConst('ACTION').CashBook.List
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive('/quan-ly-so-quy/so-quy-tien-mat'),
              },attrs:{"icon":"handshake","label":"Sổ quỹ tiền mặt","tag":"router-link","to":"/quan-ly-so-quy/so-quy-tien-mat"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').Mifos,
                  _vm.$getConst('ACTION').CashBook.ListBank
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive('/quan-ly-so-quy/so-quy-ngan-hang'),
              },attrs:{"icon":"handshake","label":"Sổ quỹ ngân hàng","tag":"router-link","to":"/quan-ly-so-quy/so-quy-ngan-hang"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').Mifos,
                  _vm.$getConst('ACTION').CashBook.ListBank
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive('/quan-ly-so-quy/so-quy-vi-dien-tu'),
              },attrs:{"icon":"handshake","label":"Sổ quỹ ví","tag":"router-link","to":"/quan-ly-so-quy/so-quy-vi-dien-tu"}}):_vm._e(),(
                _vm.checkAccessRole(
                  _vm.$getConst('MODULE').Mifos,
                  _vm.$getConst('ACTION').CashBook.ListBank
                )
              )?_c('b-menu-item',{class:{
                'route-active': _vm.checkActive('/quan-ly-so-quy/so-quy-gpay-cimb'),
              },attrs:{"icon":"handshake","label":"Sổ quỹ GPay-CIMB","tag":"router-link","to":"/quan-ly-so-quy/so-quy-gpay-cimb"}}):_vm._e()],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
const Constants = {
  ROUTER: {
    // Nhận quỹ
    FundReceive: {
      List: "/quan-ly-quy/danh-sach-nhan-quy",
    },
    //Chuyển quỹ
    FundTransfer: {
      List: "/quan-ly-quy/danh-sach-chuyen-quy",
    },
    //Kiểm kê quỹ và sec
    FundInventorySec: {
      List: "/quan-ly-quy/danh-sach-kiem-ke",
      Create: "/quan-ly-quy/tao-kiem-ke-quy-sec",
      ListRev:"/quan-ly-quy/danh-sach-cho-xu-ly"
    },
    WithdrawSec: {
      List: "/quan-ly-quy/danh-sach-rut-sec-nhap-quy",
    },
    CashBook: {
      List: "/quan-ly-so-quy/so-quy-tien-mat",
      ListBank: "/quan-ly-so-quy/so-quy-ngan-hang",
      ListWallet:"/quan-ly-so-quy/so-quy-vi-dien-tu",
      ListGpay:"/quan-ly-so-quy/so-quy-gpay-cimb",
      ListAgent:"/quan-ly-so-quy/so-quy-agent"
    },
  },
  MODULE: {
    FundReceive: "FundReceives",
    FundInventorySec: "InventorySec",
    FundTransfer: "FundTransfers",
    WithdrawSec: "WithdrawSec",
    CashBook: "CashBooks",
    Mifos: "Mifos",
    FundTransferImages: "FundTransferImages",
  },
  ACTION: {
    FundReceive: {
      List: ["List", "ListFundReceive"],
      Export: ["Export", "CreateExportFundReceive"],
      Approve: ["Approve", "CreateTransactionFund"],
      Delete: ["Delete", "DeleteUndoTransactionFund"],
      Print: ["Print", "Print"],
    },
    FundInventorySec: {
      Create: ["Create", "CreateInventorySec"],
      List: ["List", "ListInventorySec"],
      Approve:["Approve","UpdateInventorySecApporve"],
      Images:["Update","UpdatePawnAsyn"],
      Print: ["Print", "Print"],
      PDF: ["PDF", "PDF"],
      Export:["Export","CreateExportExcel"],
      ListRev:["List","AdvancePending"]
    },
    FundTransfer: {
      List: ["List", "ListFundTransfer"],
      Create: ["Create", "CreateFundTransfer"],
      Export: ["Export", "CreateExportFundTransfer"],
      Print: ["Print", "Print"],
      Attactment: ["Update", "CreateFundTransferImage"],
    },
    WithdrawSec: {
      List: ["List", "ListWithdrawSec"],
      Approve: ["Approve", "CreateTransactionFund"],
      Delete: ["Delete", "DeleteUndoTransactionFund"],
      Print: ["Print", "Print"],
      Attactment: ["Update", "CreateFundTransferImage"],
      Create:["Create","CreateWithdrawSec"]
    },
    CashBook: {
      List: ["List", "ListCashBook"],
      ListBank: ["ListBank", "ListCashBookBank"],
      Export:["Export","ExportCashBook"],
      ExportBank:["ExportBank","ExportCashBookBank"],
      ListWallet:["ListWallet","ListCashBookWallet"],
      ExportWallet:["ExportWallet","ExportCashBookWallet"],
      ListGpay:["ListGpay","ListGPayBook"],
      ExportGPay:["ExPortGPay","ExportGPayBook"],
      ListAgent:["ListAgent","ListAgentBook"],
      ExportAgent:["ExportAgent","ExportAgentBook"]
    },
  },
  // Trạng thái quỹ
  FUNDSTATUS: {
    Complete: "01", //Hoàn thành
    Cancelled: "02", //Đã hủy
    WaitConfirm: "03", //Chờ xác nhận
    Fail: "04", // Giao dịch thất bại
  },
  //Thao tác trên danh sách kiểm kê quỹ và séc tồn
  FUNC_INVETORY_SEC: {
    Approve: "Approve",
    Image: "Image",
    Print: "Print",
    PDF: "PDF",
  },
  INVENTORY_SEC_REASON_TYPE: {
    Excesses: "Excesses",
    Lack: "Lack",
  },
  INVENTORY_SEC_STATUS_SEARCH: [
    { Code: "All", Name: "Tất cả" },
    { Code: "Activated", Name: "Chờ duyệt" },
    { Code: "Approved", Name: "Đã duyệt" },
  ],
};

const API = {
  FundTransfer: {
    List: "FundTransfers/ListFundTransfer",
    Create: "FundTransfers/CreateFundTransfer",
    ExportExcel: "FundTransfers/CreateExportFundTransfer",
  },
};
Constants.install = function (Vue) {
  Vue.prototype.$getConst = (key) => {
    return Constants[key];
  };

  Vue.prototype.$getAPI = (key) => {
    return API[key];
  };
};
export default Constants;
</script>

import axios from "axios";
import UserService from "@/modules/user/services/UserService.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import store from "@/store";
import router from "@/router";

const baseDomain = process.env.VUE_APP_SSO;
const baseURL = `${baseDomain}`;
const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(function(config) {
  let authKey = store.token;
  if (!authKey) {
    authKey = UserService.authHeader();
  }
  let encodedCodeKey = UserService.encodedCodeHeader();
  config.headers["Authorization"] = "Bearer " + authKey;
  config.headers["EncodedCode"] = encodedCodeKey;
  store.state.isLoading = true;
  return config;
});

instance.interceptors.response.use(
  function(response) {
    store.state.isLoading = false;
    return response.data;
  },
  function(error) {
    if (error.response.status == 401) {
      router.push("/dang-nhap");
      return Promise.reject(error);
    }
    if (error.response.status == 511) {
      ToastHelper.OpenToast(error.response.data, "is-warning");
      setTimeout("window.location.reload()", 5000);
    } else ToastHelper.OpenToast(error.response.data, "is-danger");
    store.state.isLoading = false;
    return Promise.reject(error);
  }
);
export default instance;

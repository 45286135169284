<script>
import Repository from "@/repositories/Repository.vue";
import RepositorySSO from "@/repositories/SSO/Repository.vue";
import Enum from "@/helpers/Enum.vue";

const authURL = process.env.VUE_APP_SSO;
const authorURL = process.env.VUE_APP_SSO;
const shareServiceUrl = process.env.VUE_APP_MASTERDATA_DOMAIN;

const login = async (user) => {
  return await Repository.post(Enum.API.Post_AdminLogin, user);
};

const getUserProfile = async () => {
  const config = {
    headers: { Authorization: `${localStorage.getItem("AccessToken")}` },
  };
  return await Repository.get(authURL + Enum.API.Get_UserProfile, config);
};

const getListTransaction = async (code) => {
  // const config = {
  //   headers: { Authorization: `${localStorage.getItem("AccessToken")}` }
  // };
  return await Repository.post(
    shareServiceUrl + Enum.API.Get_ListTransaction,
    code
  );
};

const getListRole = async (roles) => {
  return await Repository.post(authorURL + Enum.API.Post_GetListRole, roles);
};

const getActions = async (code) => {
  return await Repository.get(authURL + Enum.API.Get_GetActions+ "?role=" + code + "&module=copa");
};

const authHeader = () => {
  // let user = JSON.parse(localStorage.getItem("user"));
  // if (user) {
  //   return user.Token;
  // }
  return localStorage.getItem("AccessToken");
  // return localStorage.getItem("IdToken");
};

const encodedCodeHeader = () => {
  let role = JSON.parse(localStorage.getItem("currentRole"));
  if (role) return role.EncodedCode;
  return null;
};

const encodedSCodeHeader = () => {
  var shop = JSON.parse(localStorage.getItem("currentShop"));
  return btoa(unescape(encodeURIComponent(JSON.stringify(shop))));
};

const Get_ListEmployee=async(item)=>{
  return await RepositorySSO.post(Enum.API.SSO.ListEmployee,item);
}

const Get_ListEmployeePGD=async(code)=>{
  return await RepositorySSO.get(Enum.API.SSO.GetUSer+`?OrganizationCode=${code}&PageNumber=1&PageSize=100&IsActived=1`);
}

const getSystems = async () => {
  return await Repository.get(
    shareServiceUrl + Enum.API.Get_GetSystems
  );
}

export default {
  login,
  authHeader,
  getUserProfile,
  getListRole,
  getActions,
  encodedCodeHeader,
  encodedSCodeHeader,
  getListTransaction,
  Get_ListEmployee,
  Get_ListEmployeePGD,
  getSystems
};
</script>
